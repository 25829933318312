.file-input {
    display: none;
} 

.dropzone-title {
    font-size: 14px;
    text-align: center !important;
    margin-top: 10%;
    color: #4aa1f3;
    font-weight: bold;
}

.dropzone-content {
    background-color: white;
    display: flex;
    align-items: center;
}

.container p {
    color: red;
    text-align: center;
}

.drop-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 200px;
    border: 2px dashed #ccc;
}

.upload-icon {
    width: 50px;
    height: 50px;
    background: url(./upload.svg) no-repeat center center; 
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 30px;
}

.drop-message {
    text-align: center;
    color: #505050;
    font-family: Arial;
    font-size: 14px;
}

.file-progressBar {
    width: 100%;
    border-radius: 5px; 
    overflow: hidden;
    display:inline-block;
    margin:0px 10px 5px 5px;
    vertical-align:top;
    flex: 6 !important;
    margin-left: 50px;
    box-sizing: content-box;
    width:calc(100% - 50px)
}
    
.file-progressBar div {
    height: auto;
    color: #fff;
    text-align: right;
    line-height: 15px;
    width: 0;
    background-color: #4caf50; 
    border-radius: 3px; 
    font-size: 13px;
}
  
.file-display-container {
    width: 100%;
}
  
.file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    line-height: 50px;
    height: 50px;
}
  
.file-status-bar > div {
    overflow: hidden;
}
  
.file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}
  
.file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 50px;
    color: #4aa1f3;
}

.file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: 50px;
    color: #9aa9bb;
}

.file-error-message {
    color: red;
}
  
.file-type-logo {
    width: 50px;
    height: 50px;
    background: url(./generic.png) no-repeat center center; 
    background-size: 100%;
    position: absolute;
}
  
.file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-left:10px;
    margin-right:5px;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}
  
.file-remove  {
    position: absolute;
    top: 20px;
    right: 10px;
    line-height: 15px;
    cursor: pointer;
    color: red;
    margin-right: -10px;
    width: 25px;
    height: 25px;
    background: url(./remove.png) no-repeat center center;
}

.upload-modal {
    z-index: 999;
    display: none;
    overflow: hidden;
}

.upload-modal .overlay{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.66);
    position: absolute;
    top: 0;
    left: 0;
}

.progress-container {
    background: white;
    height: 300px;
    overflow: hidden;
}

.progress-container span {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
}

.drop-progress {
    width: 100%;
    background-color: #efefef;
    height: 20px;
    border-radius: 5px;
}

.drop-progress-bar {
    background-color: #4aa1f3;
    height: 20px;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.error {
    color: red;
}