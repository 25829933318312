.topnav-menu.custom {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}
.topnav-menu.custom .btn-success i{
    font-size: 32px;
}
.pos-biller, .pos-products {
    box-shadow: 0px 2px 3px 0px #dfdfdf;
    background-color: #fff;
}

.pos-biller .pos-item-list {
    overflow: auto; 
    height: 380px;
    min-height: 380px;
    max-height: 380px;
}

.pos-biller .pos-item-list::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #F5F5F5;
}

.pos-biller .pos-item-list::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
}

.pos-biller .pos-item-list thead th { 
    position: sticky; 
    top: 0; 
    z-index: 0; 
}
.pos-item-list table  { border-collapse: collapse; width: 100%; }
.pos-item-list th, td { padding: 8px 16px; }
.pos-item-list th     { background:#eee; }

.pos-item-list .item-trash {
    width: 25px;
}
.pos-item-list .item-price {
    width: 100px;
}
.pos-item-list .item-qty {
    width: 80px;
}

.pos-item-list .product-qty, 
.biller-input, 
.refund-items-list .product-qty {
    width: 50px;
    padding: 2px;
}

.pos-item-list .product-price,
.refund-items-list .product-price {
    width: 80px;
    padding: 2px;
    border: 0;
}
.pos-item-list .product-price:active, .pos-item-list .product-price:focus {
    border: 1px solid #ced4da;
}

.pos-biller .pos-calculation {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    background-color: #f8f8f8;
}
.pos-biller .pos-calculation-total {

}
.pos-products .navbar{
    line-height: 40px;
}
.pos-products .pos-product-list {
    min-height: 505px;
    max-height: 505px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.product-parent {
    position: relative;
    cursor: pointer;
}
.product-parent .add-to-biller{
    position: absolute;
    right: 0;
    top: 0;
}
.product-parent .stock-total {
    position: absolute;
    left: 4px;
    top: 4px;
}
.product-parent .stock-total button {
    border-radius: 25px;
}

.pos-products .product-image {
    width: 100%;
    min-height: 90px;
    border: 1px solid #ccc;
    overflow: hidden;
}
.pos-products .product-name {
    text-align: center;
}
.pos-products .product-name .title{
    font-weight: bold;
}
.pos-products .product-name .price{
    font-weight: bold;
    color: #4fbd9c;
}
.trash-icon {
    color: #3bafda;
    cursor: pointer;
}
.editing-input {
    width: 60px;
    padding: 2px;
}
.new-customer-input, .new-customer-btn {
    height: 38px;
}

.card-header {
    background: #fff;
}
.nav-tabs .nav-link {
    color: #036bc7;
}
.nav-tabs .nav-link:hover {
    border: #b1b1b133 1px solid;
}
.tag-list {
    position: relative;
}
.tag-data .tag-list {
    padding: 10px;
    font-size: 16px;
    margin-right: 15px;
}
.tag-data .tag-close {
    position: absolute;
    background: #7e7c7c;
    padding: 2px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: -8px;
    right: -8px;
    cursor: pointer;
}
.bill-select {
    cursor: pointer;
}
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.cashier-loader {
    width: 95px;
    margin: 0 auto;
}






























body[data-sidebar-color=dark] .left-side-menu #sidebar-menu>ul>li>a.active {
    color: #fff;
}
.ui-center {
    text-align: center;
}
  .carousel-container {
    position: relative;
    height: 102px;
    max-width: 960px;
    margin: 0 auto;
    overflow: hidden;
    touch-action: pan-y;
  }
  
  .carousel-track {
    display: flex;
    height: 100%;
  }
  
  .carousel-card {
    flex: 0 0 125px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: white;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
  
  .carousel-card-inner {
    flex-direction: column;
    width: 120px;
    height: 70px;
    border-radius: 5px;
    background: rgb(248 248 248) !important;
    box-shadow: 0px 0px 5px #98a6ad;
  }
  
  .carousel-title {
    color: #626262;
    font-family: 'PT Sans', sans-serif;
    text-transform: capitalize;
    margin-top: 20px;
    font-size: 16px;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }
  
  .carousel-text {
    padding: 1em;
    font-size: 14px;
    white-space: pre-wrap;
    text-align: left;
    font-family: sans-serif;
  }
  
  .carousel-pagination-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }
  
  .carousel-pagination {
    list-style: none;
    display: flex;
    justify-content: space-around;
    width: 180px;
    padding: 0;
  }
  
  .carousel-pagination li {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(50, 50, 50, 0.5);
  }
  
  .carousel-pagination .current {
    border-radius: 0;
    background: white;
  }



.onoffswitch {
    position: relative; width: 90px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #e5e5e5;
    border-radius: 20px;
}
.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}
.onoffswitch-inner:before {
    content: "ON";
    padding-left: 10px;
    background-color: #3b9b00; color: #FFFFFF;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 10px;
    background-color: #EEEEEE; color: #999999;
    text-align: right;
}
.onoffswitch-switch {
    display: block; width: 18px; margin: 6px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 56px;
    border: 2px solid #999999; border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}
.ecom-icon {
    width: 12px;
}
.loader-container {
    width: 310px;
    margin: 0 auto;
}

.tooltipp {
    position: relative;
    display: inline-block;
}

.tooltip-content {
    visibility: hidden;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltipp:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}
.return-modal .modal-body {
    height: 70vh;
}
.playable-avatar .avatar-md {
    position: relative;
}
.playable-avatar .playable-verified{
    position: absolute;
    width: 40px;
    top: 20px;
    z-index: 9999;
    right: -10px;
}
.info-block {
    line-height: 15px;
    font-size: 15px;
}

.disable-scroll {
    overflow: hidden !important;
}